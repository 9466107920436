import { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const ExplorerIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 782.02 146.19">
      <g>
        <path d="M125.26,21.97c-6.14-6.26-13.4-11.41-21.44-15.15-.33-.17-.66-.32-1-.47-1.58-.71-3.18-1.38-4.81-1.97-4.58-1.66-9.37-2.88-14.32-3.6-3.46-.5-7-.78-10.6-.78C32.79,0,0,32.79,0,73.09c0,31.4,19.9,58.23,47.76,68.56,3.28,1.21,6.66,2.2,10.14,2.93,4.91,1.04,9.99,1.6,15.2,1.6,27.76,0,51.96-15.56,64.33-38.42,5.59-10.32,8.77-22.14,8.77-34.68,0-19.89-7.99-37.94-20.92-51.13ZM21.8,112.2c-8.64-11.39-13.2-24.98-13.2-39.3,0-24.45,13.56-45.8,33.55-56.91,.53-.29,1.05,.41,.61,.82-1.83,1.72-3.58,3.56-5.25,5.52-11.59,13.55-18.26,31.98-18.32,50.58,0,24.14,16.15,50.2,42.24,50.2,23.28,0,42.23-22.52,42.23-50.2,0-13.01-4.36-25.5-11.81-34.76-.26-.33,.05-.79,.45-.67,12.59,3.72,22,18.2,22,35.44,0,15.22-5.52,30.37-15.15,41.57-9.98,11.61-23.38,18.01-37.72,18.01-15.1,0-29.56-7.41-39.63-20.28ZM125.54,33.63c8.63,11.36,13.18,24.94,13.17,39.25,0,24.45-13.56,45.8-33.55,56.92-.53,.29-1.05-.41-.61-.82,1.83-1.72,3.58-3.56,5.25-5.52,11.59-13.55,18.26-31.98,18.32-50.58,0-24.14-16.15-50.2-42.24-50.2-23.28,0-42.23,22.52-42.23,50.2,0,13.01,4.36,25.5,11.81,34.77,.26,.33-.05,.79-.45,.67-12.59-3.72-22-18.19-22-35.42,0-15.22,5.52-30.37,15.15-41.56,9.98-11.61,23.38-18.01,37.73-18.01,15.09,0,29.54,7.4,39.65,20.3Zm-29.53,39.27c0,17.24-8.38,32.12-20.38,38.8-1.49,.83-3.32,.72-4.7-.28l-2.57-1.87c-10.53-7.68-17.07-21.73-17.07-36.67,0-17.24,8.38-32.12,20.38-38.8,1.49-.83,3.32-.72,4.7,.28l2.57,1.87c10.53,7.68,17.07,21.73,17.07,36.66Z" />
        <g>
          <path d="M206.83,50.96c-3.26-2.09-7.08-3.13-11.44-3.13s-8.19,1.04-11.48,3.13c-3.29,2.09-5.83,5.07-7.63,8.96-1.8,3.89-2.7,8.52-2.7,13.89s.9,9.93,2.7,13.82c1.8,3.89,4.34,6.88,7.63,8.99,3.29,2.11,7.11,3.17,11.48,3.17s8.18-1.05,11.44-3.17c3.26-2.11,5.8-5.11,7.63-8.99,1.82-3.89,2.73-8.49,2.73-13.82s-.91-10.06-2.73-13.92c-1.82-3.86-4.37-6.84-7.63-8.92Zm-1.91,32.2c-.93,2.59-2.23,4.57-3.89,5.94-1.65,1.37-3.54,2.05-5.65,2.05s-4.01-.68-5.68-2.05c-1.68-1.37-2.99-3.35-3.92-5.94-.94-2.59-1.4-5.71-1.4-9.35s.47-6.76,1.4-9.35c.93-2.59,2.24-4.57,3.92-5.94,1.68-1.37,3.57-2.05,5.68-2.05s3.99,.68,5.65,2.05c1.66,1.37,2.95,3.33,3.89,5.9,.94,2.57,1.4,5.7,1.4,9.39s-.47,6.76-1.4,9.35Z" />
          <path d="M235.85,50.42l-12.33,45.55c-.4,1.49,.72,2.95,2.25,2.95h5.51c1.06,0,1.99-.72,2.26-1.74l1.89-7.17c.27-1.03,1.2-1.74,2.26-1.74h12.57c1.07,0,2,.72,2.26,1.75l1.85,7.15c.27,1.03,1.2,1.75,2.26,1.75h5.78c1.54,0,2.66-1.46,2.25-2.95l-12.4-45.55c-.28-1.02-1.2-1.72-2.25-1.72h-11.9c-1.05,0-1.98,.71-2.25,1.73Zm5.33,29.21c-1.53,0-2.65-1.45-2.26-2.93l2.07-7.86,.75-3.13c.57-2.39,3.97-2.39,4.54,0l.75,3.13,2.03,7.87c.38,1.48-.73,2.92-2.26,2.92h-5.63Z" />
          <path d="M321.01,51.03v3.96c0,1.29,1.05,2.34,2.34,2.34h3.75c1.29,0,2.34,1.05,2.34,2.34v28.28c0,1.29-1.05,2.34-2.34,2.34h-3.75c-1.29,0-2.34,1.05-2.34,2.34v3.96c0,1.29,1.05,2.34,2.34,2.34h22.6c1.29,0,2.34-1.05,2.34-2.34v-3.96c0-1.29-1.05-2.34-2.34-2.34h-3.75c-1.29,0-2.34-1.05-2.34-2.34v-28.28c0-1.29,1.05-2.34,2.34-2.34h3.75c1.29,0,2.34-1.05,2.34-2.34v-3.96c0-1.29-1.05-2.34-2.34-2.34h-22.6c-1.29,0-2.34,1.05-2.34,2.34Z" />
          <path d="M395.58,77.72c-.98-1.85-2.28-3.33-3.89-4.46-1.61-1.13-3.36-2.01-5.25-2.66-1.9-.65-3.8-1.2-5.72-1.65s-3.68-.94-5.29-1.44c-1.61-.5-2.89-1.16-3.85-1.98-.96-.81-1.44-1.89-1.44-3.24,0-1.87,.71-3.31,2.12-4.32,1.41-1.01,3.2-1.51,5.36-1.51,1.49,0,2.89,.25,4.14,.9,1.65,.87,2.78,2.2,3.41,3.15,.43,.65,1.16,1.03,1.94,1.03h6.3c1.63,0,2.8-1.64,2.19-3.16-.43-1.08-.97-2.08-1.64-3-1.77-2.45-4.09-4.32-6.94-5.61-2.85-1.3-5.98-1.94-9.39-1.94-3.69,0-6.91,.65-9.64,1.94-2.73,1.29-4.86,3.06-6.37,5.29-1.51,2.23-2.27,4.74-2.27,7.52s.48,4.9,1.44,6.65c.96,1.75,2.24,3.16,3.85,4.21,1.61,1.06,3.37,1.92,5.29,2.59,1.92,.67,3.83,1.25,5.72,1.73,1.9,.48,3.65,1,5.25,1.55,1.61,.55,2.89,1.26,3.85,2.12,.96,.86,1.44,2.01,1.44,3.45,0,1.97-.73,3.5-2.19,4.61-1.46,1.1-3.42,1.65-5.86,1.65-3.02,0-5.32-.89-6.91-2.66-.66-.74-1.17-1.63-1.54-2.66-.37-1.01-1.3-1.69-2.38-1.69h-5.67c-1.56,0-2.67,1.49-2.25,2.99,.38,1.36,.89,2.64,1.54,3.81,1.58,2.83,3.84,5.01,6.76,6.55,2.93,1.53,6.41,2.3,10.43,2.3,3.65,0,6.9-.64,9.75-1.91,2.85-1.27,5.1-3.02,6.73-5.25,1.63-2.23,2.45-4.83,2.45-7.81s-.49-5.24-1.48-7.09Z" />
          <path d="M309.49,77.72c-.98-1.85-2.28-3.33-3.89-4.46-1.61-1.13-3.36-2.01-5.25-2.66-1.9-.65-3.8-1.2-5.72-1.65-1.92-.46-3.68-.94-5.29-1.44-1.61-.5-2.89-1.16-3.85-1.98-.96-.81-1.44-1.89-1.44-3.24,0-1.87,.71-3.31,2.12-4.32,1.41-1.01,3.2-1.51,5.36-1.51,1.49,0,2.89,.25,4.14,.9,1.65,.87,2.78,2.2,3.41,3.15,.43,.65,1.16,1.03,1.94,1.03h6.3c1.63,0,2.8-1.64,2.19-3.16-.43-1.08-.97-2.08-1.64-3-1.77-2.45-4.09-4.32-6.94-5.61-2.85-1.3-5.98-1.94-9.39-1.94-3.69,0-6.91,.65-9.64,1.94-2.73,1.29-4.86,3.06-6.37,5.29-1.51,2.23-2.27,4.74-2.27,7.52s.48,4.9,1.44,6.65c.96,1.75,2.24,3.16,3.85,4.21,1.61,1.06,3.37,1.92,5.29,2.59,1.92,.67,3.83,1.25,5.72,1.73,1.9,.48,3.65,1,5.25,1.55,1.61,.55,2.89,1.26,3.85,2.12,.96,.86,1.44,2.01,1.44,3.45,0,1.97-.73,3.5-2.19,4.61-1.46,1.1-3.42,1.65-5.86,1.65-3.02,0-5.32-.89-6.91-2.66-.66-.74-1.17-1.63-1.54-2.66-.37-1.01-1.3-1.69-2.38-1.69h-5.67c-1.56,0-2.67,1.49-2.25,2.99,.38,1.36,.89,2.64,1.54,3.81,1.58,2.83,3.84,5.01,6.76,6.55,2.93,1.53,6.41,2.3,10.43,2.3,3.65,0,6.9-.64,9.75-1.91,2.85-1.27,5.1-3.02,6.73-5.25,1.63-2.23,2.45-4.83,2.45-7.81s-.49-5.24-1.48-7.09Z" />
        </g>
      </g>
      <g>
        <path d="M425.06,75.81h17.98c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-17.98c-.87,0-1.58-.71-1.58-1.58v-14.53c0-.87,.71-1.58,1.58-1.58h26.75c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-29.49c-1.98,0-3.59,1.61-3.59,3.59v45.02c0,.87,.71,1.58,1.58,1.58h31.79c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-27.04c-.87,0-1.58-.71-1.58-1.58v-16.4c0-.87,.71-1.58,1.58-1.58Z" />
        <path d="M497.27,49.5h-.26c-.74,0-1.42,.37-1.82,.99l-7.42,11.59-4.13,6.47c-.62,.97-2.05,.97-2.67,0l-4.06-6.39-7.42-11.66c-.4-.62-1.08-1-1.82-1h-.43c-1.7,0-2.73,1.88-1.82,3.31l12.96,20.43c.33,.52,.33,1.18,0,1.69l-13.61,21.44c-.91,1.44,.12,3.31,1.82,3.31h.23c.73,0,1.41-.37,1.81-.98l8.35-12.83,3.99-6.47c.61-1,2.06-1,2.68-.01l4.04,6.41,8.21,12.88c.4,.62,1.08,1,1.82,1h.36c1.7,0,2.73-1.88,1.82-3.31l-13.76-21.72c-.33-.52-.33-1.18,0-1.7l12.93-20.13c.92-1.44-.11-3.32-1.82-3.32Z" />
        <path d="M540.23,51.33c-2.52-1.22-5.48-1.83-8.88-1.83h-14.54c-2.34,0-4.23,1.9-4.23,4.23v43.8c0,1.19,.97,2.16,2.16,2.16h.5c1.19,0,2.16-.97,2.16-2.16v-16.32c0-.87,.71-1.58,1.58-1.58h11.29c3.55,0,6.67-.63,9.38-1.9,2.71-1.27,4.81-3.04,6.29-5.32,1.48-2.27,2.23-4.93,2.23-7.95s-.7-5.72-2.09-7.95c-1.39-2.23-3.34-3.96-5.86-5.18Zm-.75,21c-2.35,1.99-5.44,2.98-9.28,2.98h-11.22c-.87,0-1.58-.71-1.58-1.58v-18.34c0-.87,.71-1.58,1.58-1.58h12.37c3.5,0,6.32,.97,8.45,2.91,2.13,1.94,3.2,4.52,3.2,7.73s-1.17,5.89-3.52,7.87Z" />
        <path d="M564.35,49.5h-.5c-1.19,0-2.16,.97-2.16,2.16v46.45c0,.87,.71,1.58,1.58,1.58h28.69c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-23.87c-.87,0-1.58-.71-1.58-1.58V51.65c0-1.19-.97-2.16-2.16-2.16Z" />
        <path d="M633.53,51.83c-3.07-2.13-6.69-3.2-10.86-3.2s-7.79,1.07-10.86,3.2c-3.07,2.13-5.44,5.15-7.12,9.06-1.68,3.91-2.52,8.47-2.52,13.7s.84,9.78,2.52,13.66c1.68,3.88,4.05,6.9,7.12,9.06,3.07,2.16,6.69,3.23,10.86,3.23s7.79-1.08,10.86-3.23c3.07-2.16,5.44-5.18,7.12-9.06,1.68-3.88,2.52-8.44,2.52-13.66s-.84-9.79-2.52-13.7c-1.68-3.91-4.05-6.93-7.12-9.06Zm2.62,34.12c-1.27,3.26-3.06,5.79-5.36,7.59-2.3,1.8-5.01,2.7-8.13,2.7s-5.82-.9-8.12-2.7c-2.3-1.8-4.09-4.33-5.36-7.59-1.27-3.26-1.9-7.05-1.9-11.36s.64-8.1,1.9-11.36c1.27-3.26,3.06-5.79,5.36-7.58,2.3-1.8,5.01-2.7,8.12-2.7s5.83,.9,8.13,2.7c2.3,1.8,4.09,4.31,5.36,7.55,1.27,3.24,1.91,7.04,1.91,11.4s-.64,8.1-1.91,11.36Z" />
        <path d="M705.5,75.81h17.98c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-17.98c-.87,0-1.58-.71-1.58-1.58v-14.53c0-.87,.71-1.58,1.58-1.58h26.75c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-29.49c-1.98,0-3.59,1.61-3.59,3.59v43.01c0,1.98,1.61,3.59,3.59,3.59h29.78c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-27.04c-.87,0-1.58-.71-1.58-1.58v-16.4c0-.87,.71-1.58,1.58-1.58Z" />
        <path d="M777.13,72.87c1.58-1.34,2.79-2.86,3.63-4.57,.84-1.7,1.26-3.49,1.26-5.36,0-2.83-.71-5.25-2.12-7.26-1.41-2.01-3.34-3.55-5.79-4.6-2.44-1.05-5.2-1.58-8.27-1.58h-16.92c-1.26,0-2.28,1.02-2.28,2.28v45.63c0,1.26,1.02,2.28,2.28,2.28h.19c1.26,0,2.28-1.02,2.28-2.28v-17.95c0-1.26,1.02-2.28,2.28-2.28h4.21c1.45,0,2.81,.67,3.7,1.82l15.28,19.81c.43,.56,1.1,.89,1.8,.89h0c1.89,0,2.96-2.18,1.8-3.67l-13.03-16.84c-.66-.85-.19-2.11,.87-2.3,.95-.17,2.04-.43,3.04-.84,2.28-.77,4.21-1.82,5.79-3.16Zm-13.45,.07h-9.78c-1.39,0-2.52-1.13-2.52-2.52v-14.98c0-.9,.73-1.63,1.63-1.63h12.82c2.01,0,3.86,.37,5.54,1.11,1.68,.74,3.02,1.79,4.03,3.13,1.01,1.34,1.51,2.97,1.51,4.89,0,2.01-.58,3.78-1.73,5.28-1.15,1.51-2.71,2.67-4.67,3.49-1.97,.82-4.24,1.22-6.83,1.22Z" />
        <path d="M683.65,72.87c1.58-1.34,2.79-2.86,3.63-4.57,.84-1.7,1.26-3.49,1.26-5.36,0-2.83-.71-5.25-2.12-7.26-1.41-2.01-3.34-3.55-5.79-4.6-2.44-1.05-5.2-1.58-8.27-1.58h-16.92c-1.26,0-2.28,1.02-2.28,2.28v45.63c0,1.26,1.02,2.28,2.28,2.28h.19c1.26,0,2.28-1.02,2.28-2.28v-17.95c0-1.26,1.02-2.28,2.28-2.28h4.21c1.45,0,2.81,.67,3.7,1.82l15.28,19.81c.43,.56,1.1,.89,1.8,.89h0c1.89,0,2.96-2.18,1.8-3.67l-13.03-16.84c-.66-.85-.19-2.11,.87-2.3,.95-.17,2.04-.43,3.04-.84,2.28-.77,4.21-1.82,5.79-3.16Zm-13.45,.07h-9.78c-1.39,0-2.52-1.13-2.52-2.52v-14.98c0-.9,.73-1.63,1.63-1.63h12.82c2.01,0,3.86,.37,5.54,1.11,1.68,.74,3.02,1.79,4.03,3.13,1.01,1.34,1.51,2.97,1.51,4.89,0,2.01-.58,3.78-1.73,5.28-1.15,1.51-2.71,2.67-4.67,3.49-1.97,.82-4.24,1.22-6.83,1.22Z" />
      </g>
    </SvgIcon>
  )
}
