// Name of the colors -> https://www.color-name.com/
// For consistency use lowercase characters

export const COLORS = {
  antiFlashWhite2: '#f1f4f9',
  antiFlashWhite3: '#f0f3f5',
  antiFlashWhite: '#ecf1f6',
  brandDark: '#3333c4',
  brandExtraDark: '#000062',
  brandExtraLight: '#e5e5ef',
  brandLight: '#6665d8',
  brandLightBlue: '#E8F5FF',
  brandMedium: '#0092f6',
  brandMedium15: '#d9effe',
  brightGray2: '#ececec',
  brightGray: '#e6edf3',
  ceil: '#8f8cdf',
  cosmicCobalt: '#222199',
  darkBlueGray: '#5c6098',
  darkBlue: '#030092',
  error: '#d32f2f',
  eucalyptus: '#4cd4a9',
  grayDark: '#31435a',
  grayExtraDark: '#06152b',
  grayLight: '#f4f5f7',
  grayMedium: '#565b61',
  grayMedium2: '#d5d6d7',
  grayMediumLight: '#e3e8ed',
  honeydew: '#e8faf3',
  lavenderGray: '#c5c7ca',
  lightGreen: '#e0f5ee',
  lightSilver: '#d9d9d9',
  linen: '#fae8e8',
  // persianBlue: '#3038c3', use brandDark instead
  platinum: '#e2e2e2',
  vistaBlue: '#8988dd',
  white: '#ffffff',
  white025A: '#ffffff40',
  spaceCadet: '#0f0f5f',
  denimBlue: '#3138bc',
  disabledPagination: '#7575a7',
  purpleBackground: '#e0e0f4',
  inactiveTab: '#e5e5f6',
  inactiveStroke: '#aaaaadb0',
  disabledPrimaryBackground: '#acadb0',
  disabledPrimaryText: '#d5d6d7',
  errorIndicatorBackground: '#d44c4c',
  aqua: '#00feff',
  shadowBlue: '#8484ab',
  black: '#000000',
  warningColor: '#ed6c02',
  warningBackground: '#faf5e8',
  warningLight: '#fff0e4',
  iconBackground: '#d4d3f2',
  graphLabel: '#191932',
  graphLine: '#01F1E3',
  paraTimeStatus: '#8081ac',
  pink: '#ed32fa',
  pink15: '#fce0fe',
} satisfies { [colorName: string]: string }
