import { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const OasisIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="145 145 790 790">
      <path d="M819.3,266.28c-32.86-33.52-71.73-61.06-114.78-81.11-1.75-.9-3.53-1.72-5.37-2.49-8.46-3.78-17.01-7.38-25.77-10.56-24.51-8.91-50.16-15.41-76.66-19.28-18.53-2.7-37.46-4.16-56.72-4.16-215.77,0-391.32,175.54-391.32,391.32,0,168.1,106.56,311.76,255.67,367.04,17.54,6.5,35.67,11.76,54.28,15.71,26.26,5.58,53.47,8.57,81.36,8.57,148.63,0,278.16-83.31,344.39-205.68,29.91-55.27,46.93-118.5,46.93-185.64,0-106.47-42.79-203.09-112.02-273.72ZM265.4,749.35c-46.24-60.99-70.68-133.75-70.68-210.42,0-130.92,72.58-245.19,179.61-304.7,2.83-1.58,5.63,2.18,3.27,4.4-9.78,9.21-19.16,19.05-28.13,29.53-62.03,72.55-97.77,171.21-98.08,270.77,0,129.26,86.46,268.77,226.15,268.77,124.66,0,226.07-120.57,226.07-268.77,0-69.63-23.34-136.49-63.2-186.11-1.41-1.75.26-4.23,2.41-3.6,67.38,19.93,117.77,97.42,117.77,189.71,0,81.48-29.56,162.59-81.11,222.56-53.43,62.15-125.15,96.4-201.94,96.43-80.87,0-158.26-39.65-212.15-108.56ZM820.8,328.72c46.2,60.84,70.58,133.49,70.51,210.14,0,130.91-72.58,245.19-179.62,304.7-2.83,1.58-5.63-2.18-3.27-4.4,9.78-9.21,19.16-19.06,28.13-29.53,62.03-72.54,97.77-171.21,98.08-270.77,0-129.26-86.46-268.77-226.15-268.77-124.66,0-226.07,120.57-226.07,268.77,0,69.63,23.35,136.5,63.21,186.12,1.4,1.75-.26,4.23-2.41,3.6-67.39-19.92-117.78-97.39-117.78-189.64,0-81.46,29.56-162.56,81.12-222.51,53.45-62.16,125.19-96.4,202.01-96.4,80.81,0,158.17,39.62,212.25,108.69ZM662.71,538.93c0,92.31-44.84,171.97-109.11,207.74-7.96,4.43-17.77,3.88-25.14-1.49l-13.75-10.03c-56.37-41.13-91.39-116.35-91.39-196.29,0-92.3,44.84-171.96,109.12-207.73,7.97-4.43,17.78-3.88,25.14,1.49l13.74,10.03c56.37,41.13,91.39,116.34,91.39,196.29Z" />
    </SvgIcon>
  )
}
